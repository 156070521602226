@import 'bootstrap/bootstrap-grid';

$grid-gutter-small: 5px;

@media (max-width: map-get($grid-breakpoints, md) - 1px) {
  .row {
    margin-right: -$grid-gutter-small;
    margin-left: -$grid-gutter-small;
  }

  .row>.col,
  .row>[class*="col-"] {
    padding-right: $grid-gutter-small;
    padding-left: $grid-gutter-small;
  }
}

@media (min-width: map-get($grid-breakpoints, sm)) and (max-width: map-get($grid-breakpoints, lg) - 1px) {
  .container {
    padding-left: 0;
    padding-right: 0;
  }
}